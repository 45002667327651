<template>
    <div class="testimonials-area bg-1d2b53 ptb-100">
        <div class="container">
            <div class="section-title white-color">
                <span class="sub-title">Testimonials</span>
                <h2>Here's What Our Amazing Clients Say</h2>
            </div>

            <div class="review-section">
                <h3>Company Reviews</h3>
                <div class="review-list company-review-list">
                    <div class="review-card" v-for="review in companyReviews" :key="review.id">
                        <p>{{ review.text }}</p>
                        <p><strong>{{ review.author }}</strong></p>
                        <p>{{ review.company }}</p>
                    </div>
                </div>
            </div>

            <div class="review-section">
                <h3>Customer Reviews</h3>
                <div class="review-list customer-review-list">
                    <div class="review-card" v-for="review in customerReviews" :key="review.id">
                        <p>{{ review.text }}</p>
                        <p><strong>{{ review.author }}</strong></p>
                        <p>{{ review.company }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Testimonials',
    data: () => ({
        companyReviews: [
            {
                id: 1,
                text: "Their painting service transformed our office beautifully.",
                author: 'Emily Johnson',
                company: 'Creative Spaces Inc.',
            },
            {
                id: 2,
                text: "Attention to detail was impeccable.",
                author: 'Michael Smith',
                company: 'Smith & Co. Interiors',
            },
            {
                id: 3,
                text: "A seamless experience from start to finish.",
                author: 'Sarah Lee',
                company: 'Lee Design Studio',
            }
        ],
        customerReviews: [
            {
                id: 1,
                text: '"I have never seen such a professional team at work."',
                author: 'John Doe',
                company: 'Homeowner',
            },
            {
                id: 2,
                text: '"The quality of work exceeded my expectations."',
                author: 'Jane Smith',
                company: 'Homeowner',
            },
            {
                id: 3,
                text: '"They brought my vision to life with precision."',
                author: 'Robert Brown',
                company: 'Homeowner',
            },
            {
                id: 4,
                text: '"I highly recommend their services to anyone."',
                author: 'Linda Green',
                company: 'Homeowner',
            },
        ]
    })
})
</script>

<style scoped>
.review-section {
  margin-bottom: 30px;
}

.review-list {
  display: grid;
  gap: 20px;
}

.company-review-list,
.customer-review-list {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.review-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
}

.review-card p {
  margin-bottom: 10px;
}
</style>