<template>
    <div class="software-banner-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="software-banner-content">
                        
                        <h1>Exceptional Solutions for Residential and Commercial Painting.</h1>
                        <p>Vegas Quality Painting, your trusted partner for transforming spaces with precision and care. Experience the difference with our expert team and cutting-edge techniques.</p>
                        <button @click="$emit('modalOpen')" class="btn-style-one green-light-color">
                            SCHEDULE YOUR FREE CONSULTATION
                            <i class="ph-caret-right"></i>
                        </button>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="software-banner-image" data-aos="fade-up">
                        <img src="../../assets/images/banner/vegas-quality-painting.jpg" alt="banner-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>