<template>
    <div class="template-footer-nine pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="single-footer-widget">
                        <router-link to="/" class="logo">
                            <img src="../../assets/images/logo.png" alt="logo" width="150" height="150">
                        </router-link>
                        <p> Asheboro NC 27203</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="single-footer-widget pl-2">
                        <h3>Company</h3>
                        <ul class="links-list">
                            <li><a >VegasQualityPainting</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="single-footer-widget">
                        <h3>Contact</h3>
                        <ul class="links-list">
                            <li><a href="mailto:info@vegasqualitypainting.com">info@vegasqualitypainting.com</a></li>
                            <li><a href="tel:+3362676114">+1 3362676114</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="copyright-area">
                <p>
                    Todos los Derechos Reservados por VegasQualityPainting. Copyright @{{ currentYear }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterStyleNine',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>
