<template>
  <div class="snipcart-order__summary">
    <h2>¡Gracias por tu compra!</h2>
    <p>Te hemos enviado un correo con los detalles de tu producto.</p>
  </div>
</template>

<script>
export default {
  name: 'SnipcartTemplate',
  mounted() {
    console.log('SnipcartTemplate montado');
    this.handleOrderCompletion();
  },
  methods: {
    handleOrderCompletion() {
      const order = JSON.parse(localStorage.getItem('snipcart-order')); // Asume que guardas la orden en localStorage
      console.log('Detalles de la orden:', order);
      // Verificar la estructura de 'order.items'
      if (Array.isArray(order.items)) {
        console.log('order.items es un array:', order.items);
      } else {
        console.error('La estructura de order.items no es válida:', order.items);
      }
    }
  }
}
</script>

<style scoped>
.snipcart-order__summary {
  text-align: center;
  margin: 20px;
}

.snipcart-button {
  display: inline-block;
  background-color: #0D9387;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.snipcart-button:hover {
  background-color: #0a6f62;
  color: #fff;
}
</style>
