import { createApp } from 'vue';
import SnipcartTemplate from './components/Software/SnipcartTemplate.vue'; // Importa el componente de la plantilla

export function initializeSnipcart() {
  console.log("Inicializando Snipcart...");

  window.SnipcartSettings = {
    publicApiKey: "NjQ3MThiNzktNmVkMi00Mzk1LWFkYmQtMTZlYmViNTAxNTk0NjM4NDgxNDAyOTI4ODM5MTEx",
    loadStrategy: "on-user-interaction",
    version: "3.7.1",
    timeoutDuration: 2750
  };

  const snipcartInterval = setInterval(() => {
    if (window.Snipcart && window.Snipcart.api) {
      console.log("Snipcart ha sido cargado exitosamente.");
      clearInterval(snipcartInterval);
      
      console.log("Snipcart esperando eventos para cargar plantillas personalizadas");

      // Suscribirse a múltiples eventos de Snipcart para el proceso de checkout
      const events = ['page.viewed', 'cart.confirmed', 'order.completed', 'cart.opened', 'cart.closed'];
      
      events.forEach(event => {
        try {
          console.log(`Intentando suscribirse al evento ${event}...`);
          window.Snipcart.events.on(event, (data) => {
            console.log(`Evento ${event} detectado:`, data);

            if (event === 'cart.confirmed') {
              console.log('Evento cart.confirmed detectado:', data);
              localStorage.setItem('snipcart-order', JSON.stringify(data)); // Guardar la orden para usarla en el componente

              // Verificar la presencia del contenedor antes de montar el componente
              const container = document.getElementById('snipcart-template-container');
              if (container) {
                console.log('Contenedor encontrado, montando el componente Vue...');
                const app = createApp(SnipcartTemplate);
                app.mount('#snipcart-template-container');
                console.log('Componente Vue montado exitosamente.');
              } else {
                console.error('Contenedor no encontrado, no se puede montar el componente Vue.');
              }
            }
          });
          console.log(`Suscripción al evento ${event} exitosa.`);
        } catch (error) {
          console.error(`Error al suscribirse al evento ${event}:`, error);
        }
      });
    }
  }, 100);

  (function() {
    console.log("Cargando Snipcart desde el CDN...");
    
    let t = document.getElementsByTagName("head")[0],
        n = document.querySelector("#snipcart"),
        i = document.querySelector('script[src^="https://cdn.snipcart.com/themes/v3.0.31/default/snipcart.js"]'),
        e = document.querySelector('link[href^="https://cdn.snipcart.com/themes/v3.0.31/default/snipcart.css"]');

    if (!n) {
      n = document.createElement("div");
      n.id = "snipcart";
      n.setAttribute("hidden", "true");
      n.dataset.apiKey = window.SnipcartSettings.publicApiKey;
      document.body.appendChild(n);
      console.log("Div de Snipcart agregado al DOM.");
    }
    
    if (!i) {
      i = document.createElement("script");
      i.src = "https://cdn.snipcart.com/themes/v3.0.31/default/snipcart.js";
      i.async = true;
      t.appendChild(i);
      console.log("Script de Snipcart cargado.");
    }
    
    if (!e && window.SnipcartSettings.loadCSS) {
      e = document.createElement("link");
      e.rel = "stylesheet";
      e.type = "text/css";
      e.href = "https://cdn.snipcart.com/themes/v3.0.31/default/snipcart.css";
      t.prepend(e);
      console.log("CSS de Snipcart cargado.");
    }
  })();
}
