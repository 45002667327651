<template>
    <div class="painting-services-area pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title" style="color: #0D9387;">KEY POINTS: PREMIER PAINTING SERVICES</span>
                <h2>Simple and Effective</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-painting-service-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">format_paint</span>
                        </div>
                        <h3>Professional Painting</h3>
                        <p>Deliver high-quality painting services for both residential and commercial properties. Ensure a fresh, new look with our expert team.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-painting-service-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">refresh</span>
                        </div>
                        <h3>Paint Removal</h3>
                        <p>Offer comprehensive paint stripping and removal services to prepare surfaces for pristine repainting and finishing.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-painting-service-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">color_lens</span>
                        </div>
                        <h3>Custom Color Consultation</h3>
                        <p>Provide expert guidance in selecting the perfect paint colors and finishes to match the unique style and atmosphere of each space.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-painting-service-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">brush</span>
                        </div>
                        <h3>Interior & Exterior Finishing</h3>
                        <p>Specialize in both interior and exterior painting projects using only the best materials for lasting durability and beauty.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-painting-service-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">build</span>
                        </div>
                        <h3>Surface Preparation</h3>
                        <p>Thorough surface preparation to ensure the best possible finish, including cleaning, sanding, and priming.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-painting-service-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">layers</span>
                        </div>
                        <h3>Protective Coatings</h3>
                        <p>Apply high-quality protective coatings to extend the life of your paintwork against the elements and wear.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-painting-service-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">update</span>
                        </div>
                        <h3>Maintenance Programs</h3>
                        <p>Offer regular maintenance plans to keep painted surfaces in top condition, extending the beauty and life of your investment.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-painting-service-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">group_work</span>
                        </div>
                        <h3>Team Coordination</h3>
                        <p>Efficient project management ensures that our team works seamlessly with other contractors to meet deadlines and quality standards.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-painting-service-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">visibility</span>
                        </div>
                        <h3>Quality Assurance</h3>
                        <p>Implement rigorous quality control measures throughout the painting process to guarantee satisfaction with every project.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaintingServices'
}
</script>
<style scoped>
.big-icon {
    font-size: 48px;
    color: #0D9387; /* Maintain the cyan color theme */
}
</style>