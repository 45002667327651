import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import BootstrapVue3 from "bootstrap-vue-3";
import Toaster from "@meforma/vue-toaster";

import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import "./assets/custom.scss";
import { initializeSnipcart } from './snipcart'; // Importa la configuración de Snipcart

const initializeApp = async () => {
  console.log("Inicializando la aplicación Vue desde main.js...");

  const app = createApp(App).use(router).use(Toaster);
  app.use(BootstrapVue3);
  app.use(AOS.init());

  console.log("Inicializando Snipcart desde main.js...");
  initializeSnipcart(); // Inicializa Snipcart

  console.log("Montando la aplicación Vue...");
  app.mount("#app");
};

initializeApp();
