<template>
    <div class="screenshots-area bg-263238 ptb-100">
        <div class="container">
            <div class="section-title white-color">               
                <h2>Vegas Quality Painting: Your Partner in Transformation</h2>
                <span class="sub-title mt-3" >Explore our comprehensive painting solutions. We combine expertise and quality in one platform, accessible from any device. Discover how we can turn your daily challenges into opportunities for enhancement!</span>
            </div>
            
            <div class="screenshots-tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="screen1-tab" data-bs-toggle="tab" data-bs-target="#screen1" type="button" role="tab" aria-controls="screen1" aria-selected="true">
                            <i class="ph-swap"></i>
                            Color Consultation
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="screen2-tab" data-bs-toggle="tab" data-bs-target="#screen2" type="button" role="tab" aria-controls="screen2" aria-selected="false">
                            <i class="ph-swap"></i>
                            Surface Preparation
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="screen3-tab" data-bs-toggle="tab" data-bs-target="#screen3" type="button" role="tab" aria-controls="screen3" aria-selected="false">
                            <i class="ph-presentation-chart"></i>
                            Project Completion
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="screen1" role="tabpanel">
                        <img src="../../assets/images/screenshots/vegas1.webp" alt="Color Consultation">
                    </div>
                    <div class="tab-pane fade" id="screen2" role="tabpanel">
                        <img src="../../assets/images/screenshots/vegas2.jpg" alt="Surface Preparation">
                    </div>
                    <div class="tab-pane fade" id="screen3" role="tabpanel">
                        <img src="../../assets/images/screenshots/vegas3.webp" alt="Project Completion">
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'Screenshots',
}
</script>