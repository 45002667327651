<template>
    <div class="app-download-area bg-1d2b53 pt-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="app-download-image">
                        <img src="../../assets/images/app-download2.png" data-aos="fade-up" data-aos-duration="1200" alt="app-download">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-download-content">
                        <span class="sub-title" data-aos="fade-up" data-aos-duration="1200">Transform Your Space</span>
                        <h2 data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">Expert Painting Services for Homes and Businesses</h2>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <button @click="$emit('modalOpen')" class="btn-style-one">
                                <div class="icon-container">
                                    <img src="../../assets/images/apple-store.png" alt="Iphone" class="icon">
                                    <img src="../../assets/images/play-store.png" alt="Android" class="icon">
                                </div>
                                <div class="text-and-icon">
                                    Contact Us Today
                                    <i class="ph-arrow-up-right"></i>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pricing'
}
</script>

<style scoped>
.app-download-area,
.testimonials-area {
    background-color: #1d2b53 !important;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.icon {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.btn-style-one {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 30px; /* adjust padding as necessary */
    background-color: white;
    color: black;
    border: none;
}

.btn-style-one:hover {
    background-color: white;
    color: black;
}

.btn-style-one .icon-container,
.btn-style-one .text-and-icon {
    margin-right: 15px; /* creates space between icons and text */
}
</style>