<template>
    <router-view></router-view>
  </template>
  
  <script>
  export default {
    name: 'App',
    data() {
      return {
        isLoading: true
      }
    },
    mounted() {
      // Puedes agregar cualquier lógica de montaje aquí si es necesario
    }
  }
  </script>
  
  <style scoped>
  /* Puedes agregar estilos aquí si es necesario */
  </style>
  