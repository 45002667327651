<template>
    <div class="help-desk-area pb-75">
        <div class="container">
            <div class="section-title">
                <span class="sub-title" style="color: #0D9387;">ADVANCED PAINTING SERVICE</span>
                <h2>BEST SERVICE IN THE AREA</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">format_paint</span>
                        </div>
                        <h3>Color Matching</h3>
                        <p>Quickly match colors for your projects with our advanced color technology. Ensure perfect tone and shade for every wall.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">refresh</span>
                        </div>
                        <h3>Paint Removal</h3>
                        <p>Efficiently remove old paint, preparing surfaces for a fresh coat with minimal downtime and disruption.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">build_circle</span>
                        </div>
                        <h3>Surface Preparation</h3>
                        <p>Prepare surfaces to the highest standards to ensure lasting quality and adherence of new paint applications.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">visibility</span>
                        </div>
                        <h3>Project Visualization</h3>
                        <p>Visualize the end results before starting through digital mockups, helping you make informed decisions about colors and finishes.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">schedule</span>
                        </div>
                        <h3>Efficient Scheduling</h3>
                        <p>Optimize painting schedules to minimize disruption to your daily operations, ensuring timely project completion.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-help-desk-box">
                        <div the="icon">
                            <span class="material-icons-outlined big-icon">group_work</span>
                        </div>
                        <h3>Team Coordination</h3>
                        <p>Coordinate painting teams effectively to maximize efficiency and maintain high standards of workmanship.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">check_circle_outline</span>
                        </div>
                        <h3>Quality Assurance</h3>
                        <p>Ensure each painting job meets our rigorous standards for quality and customer satisfaction through detailed inspections and follow-ups.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">timeline</span>
                        </div>
                        <h3>Progress Tracking</h3>
                        <p>Track the progress of your painting project in real-time, with updates available through our client portal.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-help-desk-box">
                        <div class="icon">
                            <span class="material-icons-outlined big-icon">report_problem</span>
                        </div>
                        <h3>Issue Resolution</h3>
                        <p>Quickly address any issues that arise during the painting process to maintain project timelines and quality.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HowCanHelp'
}
</script>
<style scoped>
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.big-icon {
    font-size: 48px;
    color: #0D9387; /* Change color to cyan */
}
</style>