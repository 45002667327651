<template>
  <div :class="['navbar-area', { 'is-sticky': isSticky }]">
    <div class="vumy-nav">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between align-items-center">


          <div class="navbar-toggler" @click="show = !show" :aria-pressed="show ? 'true' : 'false'"
            v-bind:class="{ show: button_show_state }" v-on:click="button_show_state = !button_show_state">
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </div>

          <div class="others-option d-flex align-items-center">
            <div class="contact-number mr-3">
              <p>Call Us: <a href="tel:+13362676114">+1 (336) 267-6114</a></p>
            </div>
            <button @click="$emit('modalOpen')" class="btn-style-one blue-dark-color">
              Get a Quote
              <i class="ph-caret-right"></i>
            </button>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      isSticky: false,
      show: false,
      button_show_state: false,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.navbar-area {
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.brand-text {
  font-family: 'Lato', sans-serif;
  font-size: 42px;
  font-weight: 700;
  color: #243B55;
  text-align: left;
  display: block;
}

.sub-title {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #0D9387;
  display: block;
  margin-top: 10px;
}

.brand-text i {
  margin-left: 10px;
  font-size: 36px;
  color: #243B55;
}

.others-option {
  display: flex;
  align-items: center;
}

.contact-number {
  margin-right: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.contact-number a {
  color: #007bff;
  text-decoration: none;
}

.contact-number a:hover {
  text-decoration: underline;
}

.btn-style-one {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-style-one:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .brand-text {
    font-size: 32px;
  }

  .sub-title {
    font-size: 16px;
  }

  .contact-number {
    font-size: 14px;
  }

  .btn-style-one {
    padding: 8px 16px;
  }
}
</style>