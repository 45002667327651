<template>
  
  <div>     
    <Navbar @modalOpen="modalShow = true"/>
    <MainBanner @modalOpen="modalShow = true"/>    
   
    <router-view></router-view> 
    <HowCanHelp/>
    <Screenshots/>
    <FieldFirst />
    <Partner/>
    <SoftwareIntegrations/>
   
    <Testimonials/>
    <Pricing @modalOpen="modalShow = true"/>
    <Faq class="with-top-border" @open-modal="modalShow = true"/>
    <AppDownload @modalOpen="modalShow = true"/>    
    <FooterStyleNine/>
    <Whatsapp rtl="false" position="right" layout="style2"/>    

    <b-modal centered hide-footer v-model="modalShow" @hidden="resetModalAfterSuccess">
      <div v-if="!isSuccess">
        <div class="d-flex align-middle">
          <h3>Contact us for a personalized consultation and a free estimate.</h3>
        </div>
        <div class="p-2">
          <form ref="form" @submit.stop.prevent="handleSubmit" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" name="contact">
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <b-form-group
                label="Email"
                label-for="email-input"
                :invalid-feedback="formErrors.email && formSubmitted ? formErrors.email : ''"
                :state="formErrors.email !== undefined ? !formErrors.email : null"
            >
              <b-form-input
                  id="email-input"
                  v-model="email"
                  required
                  name="email"
              ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Name"
                label-for="firstname-input"
                :invalid-feedback="formErrors.firstname && formSubmitted ? formErrors.firstname : ''"
                :state="formErrors.firstname !== undefined ? !formErrors.firstname : null"
            >
              <b-form-input
                  id="firstname-input"
                  v-model="firstname"
                  required
                  name="firstname"
              ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Last Name"
                label-for="lastname-input"
                :invalid-feedback="formErrors.lastname && formSubmitted ? formErrors.lastname : ''"
                :state="formErrors.lastname !== undefined ? !formErrors.lastname : null"
            >
              <b-form-input
                  id="lastname-input"
                  v-model="lastname"
                  required
                  name="lastname"
              ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Telephone"
                label-for="phone-input"
                :invalid-feedback="formErrors.phone && formSubmitted ? formErrors.phone : ''"
                :state="formErrors.phone !== undefined ? !formErrors.phone : null"
            >
              <b-form-input
                  id="phone-input"
                  v-model="phone"
                  required
                  name="phone"
              ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Residential or Company ?"
                label-for="company-input"
                :invalid-feedback="formErrors.company && formSubmitted ? formErrors.company : ''"
                :state="formErrors.company !== undefined ? !formErrors.company : null"
            >
              <b-form-input
                  id="company-input"
                  v-model="company"
                  required
                  name="company"
              ></b-form-input>
            </b-form-group>
            <div class="text-center">
              <button type="submit" class="btn-style-one blue-dark-color">Send Information</button>
            </div>
          </form>
          <div class="text-center mt-3">
            <p class="extra-small" v-html="disclaimer"></p>
          </div>
        </div>
      </div>
      <div v-if="isSuccess" class="text-center">
        <div class="alert alert-success mt-3" role="alert">
          <h4 class="alert-heading">¡Éxito!</h4>
          <p>Thank you for reaching out! Our painting experts will contact you shortly to discuss your project needs and provide the best solutions.</p>
          <hr>
          <p class="mb-0">Thanks for contact Us.</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>



<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../Software/MainBanner'
import HowCanHelp from '../Software/HowCanHelp'
//import CrewVideo from '../Software/CrewVideo.vue'
import Screenshots from '../Software/Screenshots'
import Partner from '../Software/Partner'
import SoftwareIntegrations from '../Software/SoftwareIntegrations'
//import Funfacts from '../Software/Funfacts'
import Testimonials from '../Common/Testimonials'
import Pricing from '../Software/Pricing'
import Faq from '../Common/Faq'
import Blog from '../Common/Blog'
import Whatsapp from '../Common/Whatsapp'
import AppDownload from '../Software/AppDownload'
import FooterStyleNine from '../Layouts/FooterStyleNine'
import FieldFirst from "@/components/Software/FieldFirst.vue";
import axios from 'axios';
import messaging from "@/config/firebaseInit"; 
import { getToken } from 'firebase/messaging';


export default {
  name: 'SoftwarePage',
  components: {
    FieldFirst,
    Navbar,
    MainBanner,    
    HowCanHelp,
   // CrewVideo,
    Screenshots,
    Partner,
    SoftwareIntegrations,
    //Funfacts,
    Testimonials,    
    Faq,
    Blog,
    Whatsapp,
    AppDownload,
    FooterStyleNine,
  },

  /*async created() {
    try {    
    
    const currentToken = await getToken(messaging, {vapidKey: 'BL_NPPrHT9-15RYk8-EzWl3zxN7n_gNgGIMlINQHoLI5NQ_v3pPnFU8bN4grnK8rUsq604Y-WvwtRFJefZnuj_Y'}); // Use Public Vapid Key

    if (currentToken) {
      console.log(`FCM Token: ${currentToken}`);
      try {
        const response = await axios.post('/.netlify/functions/notification', `fcmToken=${currentToken}`);
        console.log(response.data);
      } catch (axiosError) {
        console.error('Error occurred while sending FCM Token to Netlify function:', axiosError);
      }
    }
  } catch (tokenError) {
    console.error('Error occurred while getting FCM Token:', tokenError);
  }
},*/



  data() {
    return {
      modalShow: false,
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      company: '',
      emailState: null,
      firstnameState: null,
      lastnameState: null,
      phoneState: null,
      companyState: null,
      isSuccess: false,
      disclaimer: `By providing your information above and clicking the "Request Consultation and Trial Account" button, you agree to our <a href='https://www.vegasqualitypainting.com/privacy-policy' style='text-decoration: underline;'>privacy policy</a> and consent to being contacted at the phone number and email address you provide in this form regarding our painting services.`,
      formSubmitted: false,
      formErrors: {},
    }
  },
  methods: {
    handleSubmit() {
      this.formSubmitted = true;
      this.formErrors = {
        email: this.validateEmail(),
        firstname: this.validateFirstname(),
        lastname: this.validateLastname(),
        phone: this.validatePhone(),
        company: this.validateCompany(),
      };

      // form is valid only if all error messages are empty
      const isFormValid = Object.values(this.formErrors).every(error => error === "");

      if (isFormValid) {
        // Collect form data
        const formData = new FormData(this.$refs.form);

        // Send form data to Netlify
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString()
        }).then(() => console.log('Form successfully submitted to Netlify'))
          .catch((error) => console.error(error));

        // Send form data to the first SMS function
       
        fetch("/.netlify/functions/sms1", {  
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString()
        }).then(() => console.log('Form successfully submitted to sms1 function'))
          .catch((error) => console.error(error));

        // Send form data to the second SMS function
        fetch("/.netlify/functions/sms2", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString()
        }).then(() => console.log('Form successfully submitted to sms2 function'))
          .catch((error) => console.error(error));
         

        // Send form data to the WhatsApp function
        fetch("/.netlify/functions/whatsapp", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString()
        }).then(() => {
          // Form successfully submitted, show success message
          this.isSuccess = true;
          this.resetModal();
        }).catch((error) => console.error(error));

        // Send form data to the Firebase Notifications function
       fetch("/.netlify/functions/notification", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString()
        }).then(() => console.log('Form successfully submitted to Firebase Notifications'))
          .catch((error) => console.error(error)); 
      }
    },
    validateForm() {
      return this.validateEmail() &&
        this.validateFirstname() &&
        this.validateLastname() &&
        this.validatePhone() &&
        this.validateCompany();
    },
    resetModal() {
      this.email = ''
      this.firstname = ''
      this.lastname = ''
      this.phone = ''
      this.company = ''
      this.emailState = true
      this.firstnameState = true
      this.lastnameState = true
      this.phoneState = true
      this.companyState = true
      setTimeout(() => {
        this.formSubmitted = false;
        this.modalShow = false;
      }, 5000);
    },
    validateEmail() {
      const email = this.email
      if (email.length < 5 || !email.includes('@') || !email.includes('.')) {
        return "Correo electrónico debe tener al menos 5 caracteres y contener '@' y '.'";
      }
      return "";
    },

    validateFirstname() {
      const firstname = this.firstname
      if (firstname.length < 2) {
        return "Nombre debe tener al menos 2 caracteres";
      } else if (firstname.length > 150) {
        return "Nombre no puede tener más de 150 caracteres";
      }
      return "";
    },

    validateLastname() {
      const lastname = this.lastname
      if (lastname.length < 2) {
        return "Apellido debe tener al menos 2 caracteres";
      } else if (lastname.length > 150) {
        return "Apellido no puede tener más de 150 caracteres";
      }
      return "";
    },

    validatePhone() {
      const phone = this.phone
      if (phone.length < 10) {
        return "Teléfono debe tener al menos 10 caracteres";
      } else if (phone.length > 15) {
        return "Teléfono no puede tener más de 15 caracteres";
      }
      return "";
    },

    validateCompany() {
      const company = this.company
      if (company.length < 1) {
        return "Empresa debe tener al menos 1 caracter";
      } else if (company.length > 150) {
        return "Empresa no puede tener más de 150 caracteres";
      }
      return "";
    },
    resetModalAfterSuccess() {
      this.email = '';
      this.firstname = '';
      this.lastname = '';
      this.phone = '';
      this.company = '';
      this.formSubmitted = false;
      this.isSuccess = false;
      this.formErrors = {};
    },
  },
  watch: {
    email: function() {
      this.validateEmail()
    },
    firstname: function() {
      this.validateFirstname()
    },
    lastname: function() {
      this.validateLastname()
    },
    phone: function() {
      this.validatePhone()
    },
    company: function() {
      this.validateCompany()
    },
  },

}
</script>

<style scoped>
.alert {
  margin: 1rem auto;
  max-width: 500px;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.extra-small {
    font-size: 0.8em;
}
.b-modal .modal-header {
  border-bottom: none !important;
}
</style>