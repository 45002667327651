<template>
  <div class="faq-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration="1200">
              <div class="section-title">
                <h2>Painting Excellence <sup>TM</sup> <br> We Help You. We Innovate for Your Spaces.</h2>
              </div>
              <div class="" data-aos="fade-up" data-aos-duration="1200">
                <p>Painting should be a solution, not a complication. Especially for our dedicated teams in the field.</p>
              </div>
              <div class="" data-aos="fade-up" data-aos-duration="1200">
                <p>Painting Excellence is our commitment to prioritizing the needs of those who are directly enhancing and transforming your spaces into vibrant, beautifully painted areas.</p>
              </div>
            </div>
            <div class="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration="1200">
                <div class="faq-image style-three" data-aos="fade-up">
                    <img class="rounded-image" src="../../assets/images/overview/vegas4.webp" alt="faq-image">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'FieldFirst',
}
</script>

<style scoped>
.rounded-image {
    border-radius: 50%;
}
</style>