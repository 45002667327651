import { createWebHistory, createRouter } from "vue-router";
import SoftwarePage from "../components/Pages/SoftwarePage";
import ProductBanner from "../components/Software/ProductBanner";

const routes = [
  { path: "/", name: "SoftwarePage", component: SoftwarePage },
  { path: "/products/:id", name: "ProductBanner", component: ProductBanner }, 
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
