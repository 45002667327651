<template>
    <div class="partner-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="partner-title">
                        Trusted by Homeowners and Businesses Alike:
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="partner-item-lists">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="partner-item">
                                    <p>Exceptional Quality</p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="partner-item">
                                    <p>Reliable Service</p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="partner-item">
                                    <p>Customer Satisfaction</p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                                <div class="partner-item">
                                    <p>Innovative Solutions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Partner'
}
</script>

<style scoped>
.partner-item {
  background: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.partner-item p {
  margin: 0;
  font-weight: bold;
  color: #333;
}
</style>