<template>
  <div class="software-banner-area">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="software-banner-content">
            <span class="sub-title" style="color: #0D9387;">Producto Digital</span>
            <h1>{{ product ? product.name : '' }}</h1>
            <p>{{ product ? product.description : '' }}</p>
            <p>Precio: ${{ product ? parseFloat(product.price).toFixed(2) : '' }}</p>


            <button class="btn-style-one green-light-color snipcart-add-item" 
              :data-item-id="product ? product.id : ''"
              :data-item-name="product ? product.name : ''" 
              :data-item-price="product ? product.price : ''"
              :data-item-description="product ? product.description : ''"
              :data-item-image="product ? `/images/products/${product.image}` : ''"
              :data-item-currency="product ? product.currency : ''"
              :data-item-url="product ? `https://sensational-fenglisu-913b2f.netlify.app/.netlify/functions/validate-webhook?id=${product.id}` : ''"
              :data-item-file-guid="product ? product.fileGuid : ''">
              Comprar ahora
            </button>



          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="software-banner-image" data-aos="fade-up">
            <img :src="product ? `/images/products/${product.image}` : ''" alt="product-image">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'ProductBanner',
  setup() {
    const product = ref(null);
    let intervalId = null;

    const fetchProduct = async () => {
      try {
        const response = await fetch('/.netlify/functions/get-products');
        const products = await response.json();
        product.value = products[0]; 
        console.log('Producto cargado:', product.value); 
      } catch (error) {
        console.error('Error al cargar el producto:', error);
      }
    };

    const checkSnipcartReady = () => {
      if (window.Snipcart && window.Snipcart.api) {
        console.log('Snipcart está listo para usarse desde ProductBanner.vue.');
        clearInterval(intervalId);
        fetchProduct();
      }
    };

    onMounted(() => {
      console.log('API Key de Snipcart:', process.env.VUE_APP_SNIPCART_API_KEY);
      intervalId = setInterval(checkSnipcartReady, 100);
    });

    onUnmounted(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    });

    return {
      product
    };
  }
};
</script>

<style scoped>
/* Estilos específicos para ProductBanner.vue */
.software-banner-area {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f9f9f9;
}

.container-fluid {
  max-width: 1140px;
  margin: 0 auto;
}

.row {
  display: flex;
  align-items: center;
}

.software-banner-content {
  padding: 0 15px;
}

.sub-title {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #0D9387;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 1.2;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
}

.btn-style-one {
  display: inline-block;
  background-color: #0D9387;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.btn-style-one:hover {
  background-color: #0a6f62;
  color: #fff;
}

.software-banner-image img {
  max-width: 100%;
  border-radius: 10px;
}

.loading-placeholder {
  padding: 20px;
  text-align: center;
}
</style>
